.Butt {
    background-color:black;
    border: none;
    color:White;
    border-radius: 4px;
    padding: 6px 32px;
    text-align: center;
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 20px;
    /* margin: 15px; */
    opacity: 0.6;
    transition: 0.3s;
   
  }
  
  .Butt:hover {
      color:White;
      opacity: 1}

#crop{
    background-color:black;
    border: none;
    color:White;
    padding: 6px;
    text-align: center;
    font-size: 16px;
  /* width:"50px"; */
    /* margin: 4px ; */
    opacity: 0.6;
    transition: 0.3s;
}

#crop:hover{
    color:White;
      opacity: 1
}

#file{
    background-color:black;
    border: none;
    border-radius: 4px;
    color:White;
    padding: 6px 32px;
    text-align: center;
    font-size: 16px;
    /* margin: 15px; */
    opacity: 0.6;
    transition: 0.3s;
}

#file:hover{
    color:White;
      opacity: 1
}

.Add {
    background-color:black;
    border: none;
    color:White;
    border-radius: 4px;
    padding: 6px 32px;
    text-align: center;
    font-size: 16px;
    margin: 15px;
    opacity: 0.6;
    transition: 0.3s;
  }
  
  .Add:hover {
      color:White;
      opacity: 1}